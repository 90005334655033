.App {
  text-align: center;
  letter-spacing: 1.2px;

  height: 100vh;
  width: 100%;

  background: url(./assets/halfpowerlogo.jpeg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center; /* Center the image */
}
