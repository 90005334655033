@font-face {
  font-family: "Minimal";
  src: local("Minimal"), url(./fonts/minimal.otf) format("opentype");
}

body {
  margin: 0;
  font-family: "Minimal", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
